<template>
  <div class="container">
    <h2>Relatorio de Validades</h2>
    <div class="buttons">
      <button class="btn btn-primary" @click="filterValidity(0)">Fora de Validade</button>
      <button class="btn btn-primary" @click="filterValidity(1)">1 mês</button>
      <button class="btn btn-primary" @click="filterValidity(6)">6 meses</button>
      <button class="btn btn-primary" @click="filterValidity(12)">12 Meses</button>
      <button class="btn btn-primary" @click="validateReport()">Todas as validades</button>
    </div>
    <h5>
      Total de Produtos: <strong>{{ report.length }}</strong>
    </h5>
    <b-table striped hover :items="report" :fields="fields" :filter="filter" thead-class="text-blue" class="table">
      <template #cell(sku)="data">
        <b-link :href="`/product/${data.item.id_backoffice}`">{{ data.item.sku[0] }}</b-link>
        <p v-if="data.item.sku.length > 1">
          (
          <a v-for="prod in data.item.sku" :key="prod">
            <a v-if="prod != data.item.sku[0]">
              {{ prod }}
            </a>
          </a>
          )
        </p>
      </template>
      <template #cell(ean)="data">
        <p v-for="prod in data.item.ean" :key="prod">
          {{ prod }}
        </p>
      </template>
      <template #cell(validity)="data">
        {{ splitDate(data.item.validity) }}
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data: function () {
    return {
      report: [],
      filter: null,
      fields: [
        {
          key: 'sku',
          label: 'SKU/CNP',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'ean',
          label: 'EAN',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'name',
          label: 'Nome',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'stock',
          label: 'Stock',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'validity',
          label: 'Validade',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
      ],
    };
  },
  methods: {
    async validateReport() {
      try {
        await this.$store.dispatch('validityReport');
        console.log(this.getValidityReport);
        this.report = this.getValidityReport;
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
    async filterValidity(month) {
      try {
        await this.$store.dispatch('validateReportFilter', {
          month: month,
        });
        this.report = this.getValidityReport;
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
    routeToProduct(productId) {
      this.$router.push(`product/${productId}`);
    },
    splitDate(date) {
      let split = date.split('T');
      return split[0].substring(0, split[0].length - 3);
    },
  },
  async created() {
    this.validateReport();
  },
  computed: {
    ...mapGetters(['getValidityReport']),
  },
};
</script>

<style scoped>
.table {
  text-align: center;
}
.buttons {
  text-align: center;
  margin: 15px 0px 15px 0px;
}
h2 {
  text-align: center;
  margin-top: 5px;
}
h5 {
  text-align: center;
}
.btn {
  margin: 0px 5px 0px 5px;
}
</style>
